import $ = require("jquery");

import { forceRedraw } from "~/Src/Components/Utilities/Utilities";

import "./Transition.scss";

export class LsTransition {
    protected static initialized = false;
    protected $document: JQuery<Document>;

    // ReSharper disable InconsistentNaming
    protected static _isSupported: boolean;
    public static get isSupported(): boolean {
        return LsTransition._isSupported || (LsTransition._isSupported = $("html.csstransitions").length > 0);
    }
    // ReSharper restore InconsistentNaming

    public constructor() {
        if (!LsTransition.initialized) {
            this.$document = $(document);

            this.$document.on("transitionend", ".lsc-transition-wrapper", e => {
                if (e.target === e.currentTarget) {
                    const $el = $(e.currentTarget);
                    $el.addClass("no-transition lsu-tsn-no");
                    $el.css((e.originalEvent as TransitionEvent).propertyName, "");
                    forceRedraw(e.currentTarget as Element);
                    $el.removeClass("no-transition lsu-tsn-no");
                }
            });

            this.$document.on("transitionend", ".lsc-transition.lsc-in.lsc-after", e => {
                if (e.target === e.currentTarget) {
                    LsTransition.transitionEnd($(e.currentTarget));
                }
            });

            this.$document.on("transitionend", ".lsc-transition.lsc-out.lsc-after", e => {
                if (e.target === e.currentTarget) {
                    LsTransition.transitionEnd($(e.currentTarget), true);
                }
            });

            this.$document.on("click", '[data-toggle="transition"]', e => {
                e.preventDefault();
                const $el = $(e.currentTarget);
                const data = $el.data();
                $(data.in).each((i, el) => LsTransition.transitionBegin(el));
                $(data.out).each((i, el) => LsTransition.transitionBegin(el, true));
            });

            LsTransition.initialized = true;
        }
    }

    public static in = (el: LsJQuerySelector): void => {
        LsTransition.transitionBegin(el);
    }

    public static out = (el: LsJQuerySelector): void => {
        LsTransition.transitionBegin(el, true);
    }

    protected static transitionBegin = (element: LsJQuerySelector, out = false): void => {
        const $element = $(element);
        if ($element.length > 0) {
            const $wrapper = $element.closest(".lsc-transition-wrapper");

            const wrapperData = $wrapper.length > 0 ? $wrapper.data() : {};
            const elementData = $element.data();

            const transition: string = elementData.transition || wrapperData.transition || "lsc-fade";
            const transitionIn = elementData.transitionIn || wrapperData.transitionIn || transition;
            const transitionOut = elementData.transitionOut || wrapperData.transitionOut || transition;

            if (out) {
                $element.removeClass(transitionIn);
                $element.addClass(transitionOut);
                $element.addClass("lsc-out lsc-before lsc-transitioning");
                $element.removeClass("lsc-in lsc-after");
            } else {
                $element.prop("hidden", true);

                forceRedraw($element.get(0));
                $element.removeClass(transitionOut);
                $element.addClass(transitionIn);
                $element.addClass("lsc-in lsc-before lsc-transitioning");
                $element.removeClass("lsc-out lsc-after");

                $element.prop("hidden", false);

                if (LsTransition.isSupported && $wrapper.length > 0) {
                    const wrapperHeight = $wrapper.innerHeight();
                    const elementHeight = $element.innerHeight();

                    $wrapper.addClass("no-transition lsu-tsn-no");
                    $wrapper.css("height", wrapperHeight);
                    forceRedraw($wrapper.get(0));
                    $wrapper.removeClass("no-transition lsu-tsn-no");
                    $wrapper.css("height", elementHeight);
                    if (wrapperHeight === elementHeight) {
                        $element.one("transitionend", e => {
                            $wrapper.addClass("no-transition lsu-tsn-no");
                            $wrapper.css("height", "");
                            forceRedraw($wrapper.get(0) as Element);
                            $wrapper.removeClass("no-transition lsu-tsn-no");
                        });
                    }
                }
            }

            forceRedraw($element.get(0));
            $element.addClass("lsc-after");
            $element.removeClass("lsc-before");

            if (!LsTransition.isSupported) {
                LsTransition.transitionEnd($element, out);
            }
        }
    }

    protected static transitionEnd = ($element: JQuery, out = false): void => {
        $element.removeClass("lsc-transitioning");
        if (out) {
            const data: LsJQueryData = $element.data();
            if ((data.destroyOnOut !== undefined) && (data.destroyOnOut !== false)) {
                $element.remove();
            } else {
                $element.prop("hidden", true);
            }
        }
    }
}

export default LsTransition;