import $ = require("jquery");

import LsLogger from "~/Src/Components/Logging/Logger";

export enum LsBreakpoint {
    spf0 = 0,
    spf24 = 24,
    spf32 = 32,
    spf40 = 40,
    spf48 = 48,
    spf56 = 56,
    spf64 = 64,
    spf72 = 72,
    spf80 = 80,
    spf88 = 88,
    spf96 = 96,
}

export class LsBreakpoints {
    public static bp(bp: LsBreakpoint, unit?: true): string;
    public static bp(bp: LsBreakpoint, unit: false): number;
    public static bp(bp: LsBreakpoint, unit = true) {
        if (LsBreakpoints.isBreakpoint(bp)) {
            const value = (bp * 16) + 1;
            return unit ? `${value}px` : value;
        }
        LsLogger.log(`${bp} is not a valid breakpoint`, "error");
    }

    public static mq(bp: LsBreakpoint) {
        let breakpoint: string;
        if (breakpoint = LsBreakpoints.bp(bp)) {
            return `screen and (min-width: ${breakpoint})`;
        }
    }

    public static matches(bp: LsBreakpoint) {
        let mediaQuery: string;
        if (mediaQuery = LsBreakpoints.mq(bp)) {
            return window.matchMedia(mediaQuery)?.matches;
        }
        return false;
    }

    public static minMatch(breakpoints: Array<LsBreakpoint>) {
        const matches = breakpoints.filter(bp => LsBreakpoints.matches(bp)).sort();
        if (matches.length > 0) {
            return matches[0];
        }
        return;
    }

    public static maxMatch(breakpoints: Array<LsBreakpoint>): LsBreakpoint {
        const matches = breakpoints.filter(bp => LsBreakpoints.matches(bp)).sort();
        if (matches.length > 0) {
            return matches[matches.length - 1];
        }
        return;
    }

    protected static isBreakpoint(bp: any): bp is LsBreakpoint {
        return Object.keys(LsBreakpoint).some(k => LsBreakpoint[k] === bp);
    }
}

export default LsBreakpoints;