import $ = require("jquery");
import _debounce = require("lodash/debounce");

import { LsCallback, LsCallbackFunction, LsCallbackParameters } from "~/Src/Components/Callback/Callback";
import LsLoading from "~/Src/Components/Loading/Loading";
import LsLogger from "~/Src/Components/Logging/Logger";
import LsTransition from "~/Src/Components/Transition/Transition";
import { measureScrollbar, uniqueId } from "~/Src/Components/Utilities/Utilities";

import "./Modal.scss";

const LsModalWidths = ["auto", "xs", "sm", "md", "lg", "xl"] as const;

export interface ILsModalOptions {
    readonly canClose?: boolean;
    readonly destroyOnClose?: boolean;
    readonly loadFromCache?: boolean;
    readonly closeCurrent?: boolean;
    readonly origin?: LsJQuerySelector;
    readonly width?: typeof LsModalWidths[number];

    readonly transition?: string;
    readonly transitionIn?: string;
    readonly transitionOut?: string;

    readonly initCallback?: LsCallbackFunction;
    readonly initCallbackParameters?: LsCallbackParameters;
    readonly openCallback?: LsCallbackFunction;
    readonly openCallbackParameters?: LsCallbackParameters;
    readonly showCallback?: LsCallbackFunction;
    readonly showCallbackParameters?: LsCallbackParameters;
    readonly closeCallback?: LsCallbackFunction;
    readonly closeCallbackParameters?: LsCallbackParameters;
    readonly hideCallback?: LsCallbackFunction;
    readonly hideCallbackParameters?: LsCallbackParameters;
}

export class LsModalDefaults implements ILsModalOptions {
    public readonly destroyOnClose: boolean = false;
    public readonly loadFromCache: boolean = true;
    public readonly closeCurrent: boolean = false;
}

export class LsModal {
    public readonly id: string;
    public readonly $modal: JQuery;
    public readonly el: Element;

    public readonly options: ILsModalOptions;

    public constructor(id: string, options?: ILsModalOptions) {
        this.id = id;
        this.$modal = $(`#${this.id}`);
        this.el = this.$modal.get(0);
        this.$modal.data("modal", this);

        const $close = this.$modal.find("[data-modal-close]");
        this.options = $.extend(
            { canClose: $close.length > 0 },
            new LsModalDefaults(),
            options,
            this.$modal.data(),
            this.$modal.data("options")
        );

        if (!this.options.canClose) {
            $close.remove();
        }

        if (this.options.width) {
            if (LsModalWidths.indexOf(this.options.width) > -1) {
                const $wrapper = this.$modal.find("[data-modal-wrapper]");
                $wrapper.addClass("lsc-modal-wrapper--" + this.options.width);
            } else {
                LsLogger.log("unknown width " + this.options.width + " does not match one of the expected predefined values", "warn");
            }
        }

        if (this.options.initCallback) {
            LsCallback.call(this.options.initCallback, this.options.initCallbackParameters, this.el);
        }
        this.$modal.trigger("modalInit");
    }

    public open = (): void => {
        this.$modal.data("destroyOnOut", this.options.destroyOnClose);
        this.$modal.removeAttr("data-open-on-load");
        if (this.options.transition || this.options.transitionIn) {
            LsTransition.in(this.$modal);
        } else {
            this.$modal.prop("hidden", false);
        }
        if (this.options.openCallback) {
            LsCallback.call(this.options.openCallback, this.options.openCallbackParameters, this.el);
        }
        this.$modal.trigger("modalOpen");
    }

    public show = (): void => {
        this.$modal.data("destroyOnOut", false);
        if (this.options.transition || this.options.transitionIn) {
            LsTransition.in(this.$modal);
        } else {
            this.$modal.prop("hidden", false);
        }
        if (this.options.showCallback) {
            LsCallback.call(this.options.showCallback, this.options.showCallbackParameters, this.el);
        }
        this.$modal.trigger("modalShow");
    }

    public close = (): void => {
        this.$modal.data("destroyOnOut", this.options.destroyOnClose);
        if (this.options.transition || this.options.transitionOut) {
            LsTransition.out(this.$modal);
        } else {
            if (this.options.destroyOnClose) {
                this.$modal.remove();
            } else {
                this.$modal.prop("hidden", true);
            }
        }
        if (this.options.closeCallback) {
            LsCallback.call(this.options.closeCallback, this.options.closeCallbackParameters, this.el);
        }
        this.$modal.trigger("modalClose");
    }

    public hide = (): void => {
        this.$modal.data("destroyOnOut", false);
        if (this.options.transition || this.options.transitionOut) {
            LsTransition.out(this.$modal);
        } else {
            this.$modal.prop("hidden", true);
        }
        if (this.options.hideCallback) {
            LsCallback.call(this.options.hideCallback, this.options.hideCallbackParameters, this.el);
        }
        this.$modal.trigger("modalHide");
    }
}

export class LsModals {
    // ReSharper disable InconsistentNaming
    protected static _window: Window;
    protected static get window() {
        return LsModals._window || (LsModals._window = window);
    }

    protected static _$window: JQuery<Window>;
    protected static get $window() {
        return LsModals._$window || (LsModals._$window = $(LsModals.window));
    }

    protected static _document: Document;
    protected static get document() {
        return LsModals._document || (LsModals._document = document);
    }

    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsModals._$document || (LsModals._$document = $(LsModals.document));
    }

    protected static _html: HTMLElement;
    protected static get html() {
        return LsModals._html || (LsModals._html = LsModals.document.documentElement);
    }

    protected static _$html: JQuery;
    protected static get $html() {
        return LsModals._$html || (LsModals._$html = $(LsModals.html));
    }

    protected static _body: Element;
    protected static get body() {
        return LsModals._body || (LsModals._body = LsModals.document.body);
    }

    protected static _$body: JQuery<Element>;
    protected static get $body() {
        return LsModals._$body || (LsModals._$body = $(LsModals.body));
    }

    protected static _ids: Array<string> = [];
    public static get ids() {
        LsModals._ids = LsModals._ids.filter(id => {
            const $modal = $(`#${id}`);
            const data = $modal.data();
            return ($modal.length > 0) && !!data.modal;
        });
        return LsModals._ids.slice();
    }
    public static set ids(value) {
        LsModals._ids = value;
    }

    protected static _scrollbarWidth: string;
    public static get scrollbarWidth() {
        if (LsModals._scrollbarWidth === undefined) {
            LsModals._scrollbarWidth = measureScrollbar();
        }
        return LsModals._scrollbarWidth;
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected static mousedownTarget: Element;
    protected static mouseupTarget: Element;

    public constructor() {
        if (!LsModals.initialized) {
            $(() => {
                this.openInitialModals();
            });
            LsModals.$document.on("ajaxPageLoad", this.openInitialModals);

            LsModals.$document.on("click", '[data-toggle="modal"]:not([aria-disabled="true"])', e => {
                const $trigger = $(e.currentTarget);
                const data: LsJQueryData = $trigger.data();

                const preventDefault = !(data.preventDefault === false);
                if (preventDefault) {
                    e.preventDefault();
                }

                const href = $trigger.attr("href");
                if (data.target !== undefined) {
                    LsModals.openModalByTarget(data.target, data as ILsModalOptions);
                } else if (data.url !== undefined) {
                    LsModals.openModalByUrl(data.url, data as ILsModalOptions);
                } else if (href && href !== "#") {
                    LsModals.openModalByUrl(href, data as ILsModalOptions);
                } else {
                    const $modal = $trigger.closest("[data-modal]");
                    if ($modal.length > 0) {
                        LsModals.closeModal($modal);
                    }
                }
            });

            LsModals.$document.on("mousedown", "[data-modal]", e => {
                LsModals.mousedownTarget = e.target;
            });

            LsModals.$document.on("mouseup", "[data-modal]", e => {
                LsModals.mouseupTarget = e.target;
            });

            LsModals.$document.on("click", "[data-modal]", e => {
                if ([LsModals.mousedownTarget, LsModals.mouseupTarget, e.target].every(el => el === e.currentTarget)) {
                    const ids = LsModals.ids;
                    const $modal = ids.length > 0 ? $(`#${ids[0]}`) : $(e.currentTarget); // close topmost open modal when clicking any overlay
                    const modal = LsModals.getModal($modal);
                    LsLogger.trace(`Clicked modal overlay [${modal.id}]`);
                    if (modal.options.canClose) {
                        LsModals.closeModal($modal);
                    }
                }
            });

            LsModals.$document.on("keyup", e => {
                const ids = LsModals.ids;
                if ((e.which === 27) && (ids.length > 0)) {
                    const $modal = $(`#${ids[0]}`);
                    const modal = LsModals.getModal($modal);
                    LsLogger.trace(`Pressed escape key to close modal [${modal.id}]`);
                    if (modal.options.canClose) {
                        LsModals.closeModal($modal);
                    }
                }
            });

            LsModals.$window.on("resize", _debounce(LsModals.setCssVariables, 100));
            LsModals.setCssVariables();

            LsModals.initialized = true;
        }
    }

    public static openModalByContent = (content: LsJQuerySelector, options?: ILsModalOptions): void => {
        LsLogger.trace(`Opening modal by content.`);
        const $modal = LsModals.createModal(content, options);
        LsModals.openModal($modal);
    }

    public static openModalByTarget = (target: string, options?: ILsModalOptions): void => {
        const extendedOptions = $.extend(new LsModalDefaults(), options);

        const selector = `[data-modal][data-modal-source="${target.replace(/"/g, '\\"')}"]`;
        const $modal = $(selector);
        if ($modal.length > 0) {
            if (extendedOptions.loadFromCache) {
                LsLogger.trace(`Opening cached modal by target [${target}]`);
                LsModals.openModal($modal);
                return;
            } else {
                $modal.remove();
            }
        }

        const targetElement = LsModals._document.querySelector(target);
        if (targetElement) {
            LsLogger.trace(`Opening modal by target [${target}]`);
            const modalContent = targetElement instanceof HTMLScriptElement ? targetElement.innerHTML : targetElement;
            const $modal = LsModals.createModal(modalContent, options);
            $modal.attr("data-modal-source", target);
            LsModals.openModal($modal);
        } else {
            LsLogger.trace(`Modal target not found [${target}]`);
        }
    }

    public static openModalByUrl = (url: string, options?: ILsModalOptions): void => {
        const extendedOptions = $.extend(new LsModalDefaults(), options);

        const $modal = $(`[data-modal][data-modal-source="${url}"]`);
        if ($modal.length > 0) {
            if (extendedOptions.loadFromCache) {
                LsLogger.trace(`Opening cached modal by URL [${url}]`);
                LsLoading.hideOverlay();
                LsModals.openModal($modal);
                return;
            } else {
                $modal.remove();
            }
        }

        if (url.length > 0) {
            LsLogger.trace(`Opening modal by URL [${url}]`);
            $.ajax({
                method: "GET",
                headers: { "X-Accept": "ls-modal" },
                url: url,
                success: (result: string): void => {
                    const $modal = LsModals.createModal(result, options);
                    $modal.attr("data-modal-source", url);
                    LsModals.openModal($modal);
                },
                complete: () => {
                    LsLoading.hideOverlay();
                },
            });
        }
    }

    public static openErrorNotificationModal = (title: string = "Error", content: string = "", options?: ILsModalOptions): void => {
        LsLogger.trace(`Opening error notification modal [title=${title}][content=${LsLogger.excerpt(content, 50)}]`);
        const $modal = LsModals.createNotificationModal("error", title, content, options);
        LsModals.openModal($modal);
    }

    public static openInformationNotificationModal = (title: string = "Information", content: string = "", options?: ILsModalOptions): void => {
        LsLogger.trace(`Opening information notification modal [title=${title}][content=${LsLogger.excerpt(content, 50)}]`);
        const $modal = LsModals.createNotificationModal("information", title, content, options);
        LsModals.openModal($modal);
    }

    public static openSuccessNotificationModal = (title: string = "Success", content: string = "", options?: ILsModalOptions): void => {
        LsLogger.trace(`Opening success notification modal [title=${title}][content=${LsLogger.excerpt(content, 50)}]`);
        const $modal = LsModals.createNotificationModal("success", title, content, options);
        LsModals.openModal($modal);
    }

    public static openWarningNotificationModal = (title: string = "Warning", content: string = "", options?: ILsModalOptions): void => {
        LsLogger.trace(`Opening warning notification modal [title=${title}][content=${LsLogger.excerpt(content, 50)}]`);
        const $modal = LsModals.createNotificationModal("warning", title, content, options);
        LsModals.openModal($modal);
    }

    protected static openModal = ($modal: JQuery): void => {
        LsModals.toggleModal($modal, true);
    }

    public static closeModal = ($modal: JQuery): void => {
        LsModals.toggleModal($modal, false);
    }

    protected static toggleModal($modal: JQuery, opening: boolean) {
        if ($modal.length > 0) {
            const modal = LsModals.getModal($modal);

            if (!opening) {
                LsLogger.trace(`Closing modal [${modal.id}]`);
                modal.close();
            }

            const ids = LsModals.ids.filter(id => {
                if (id === modal.id) {
                    return false;
                }

                const $m = $(`#${id}`);
                const m = LsModals.getModal($m);

                const isParent = $.contains(m.el, modal.el);
                const isVisible = $m.is(":visible");

                if (isParent && !isVisible) {
                    m.show();
                } else if (!isParent && isVisible) {
                    if (modal.options.closeCurrent) {
                        m.close();
                        return false;
                    } else {
                        m.hide();
                    }
                }

                return true;
            });
            LsModals.ids = ids;

            if (!LsModals.initialized) {
                LsModals.setCssVariables();
            }

            if (opening) {
                modal.open();
                ids.unshift(modal.id);
                LsModals.ids = ids;
            } else if (ids.length > 0) {
                const $m = $(`#${ids[0]}`);
                const m = LsModals.getModal($m);
                m.show();
            }

            if (ids.length > 0) {
                LsModals.freezePage();
            } else {
                LsModals.unfreezePage();
            }
        }
    }

    public static closeAllModals = (): void => {
        LsLogger.trace(`Closing all modals [${LsModals.ids.join(", ")}]`);
        for (let id of LsModals.ids) {
            const $modal = $(`#${id}`);
            const modal = LsModals.getModal($modal);
            modal.close();
        }
        LsModals.ids = [];
        LsModals.unfreezePage();
    }

    public static isInModal = (el: LsJQuerySelector): boolean => {
        const $modal = $(el).closest("[data-modal]");
        return $modal.length > 0;
    }

    public static getContext(el: LsJQuerySelector): JQuery {
        return $(el).closest("[data-modal], body");
    }

    public static isInContext(el: LsJQuerySelector, context: LsJQuerySelector) {
        const $el: JQuery = $(el);
        if (!(context instanceof Element)) {
            const $context: JQuery = $(context);
            context = $context.get(0);
        }
        return $el.closest("[data-modal], body").get(0) === context;
    }

    public static findInContext(el: LsJQuerySelector, context: LsJQuerySelector): JQuery {
        const $context = $(context);
        const $el = $context.find(el as any);
        return $el.filter((i, el) => LsModals.isInContext(el, context));
    }

    protected openInitialModals = (): void => {
        const $modals = $("[data-modal][data-open-on-load]");
        if ($modals.length > 0) {
            $modals.each((i, el) => {
                const $modal = $(el);
                LsLogger.trace(`Opening initial modal [${uniqueId(el)}]`);
                LsModals.openModal($modal);
            });
        } else if (LsModals.ids.length === 0) {
            LsModals.unfreezePage();
        }
    }

    protected static createModal = (content: LsJQuerySelector, options?: ILsModalOptions): JQuery => {
        let $modal: JQuery;
        if ((typeof content === "string") && (!/^</.test(content.trim()))) {
            content = `<div>${content}</div>`;
        }
        const $content = $(content);
        if ($content.is("[data-modal]")) {
            $modal = $content;
        } else {
            const template = $("#modal-template").html();
            $modal = $(template);
            $modal.removeAttr("id");
            $modal.find("[data-modal-content]").append(content as any);
        }
        $modal.prop("hidden", true);
        $modal.data("options", options);
        if (!jQuery.contains(document.documentElement, $modal.get(0))) {
            LsModals.insertModal($modal);
        }
        return $modal;
    }

    protected static createNotificationModal = (notificationType: "error" | "information" | "success" | "warning", title: string, content: LsJQuerySelector, options?: ILsModalOptions): JQuery => {
        const template = $(`#${notificationType}-notification-modal-template`).html();
        const $modal = $(template);
        const $title = $modal.find("[data-modal-title]");
        const $content = $modal.find("[data-modal-content]");
        $modal.removeAttr("id");
        if (title) {
            $title.html(title);
        } else {
            $title.hide();
        }
        $content.append(content as any);
        $modal.prop("hidden", true);
        $modal.data("options", options);
        LsModals.insertModal($modal);
        return $modal;
    }

    protected static insertModal = ($modal: JQuery): void => {
        const data: LsJQueryData = $modal.data();
        const origin = (("options" in data) && ("origin" in data.options) && data.options.origin) || data.origin;
        if (origin !== undefined) {
            LsLogger.trace(`Appending modal [${$modal.attr("id") || ""}] to [${origin}]`);
            $modal.appendTo(origin);
        } else {
            LsLogger.trace(`Appending modal [${$modal.attr("id") || ""}] to [body]`);
            LsModals.$body.append($modal);
        }
    }

    protected static getModal = ($modal: JQuery): LsModal => {
        const data = $modal.data();
        return (data && data.modal) || new LsModal(uniqueId($modal.get(0)));
    }

    protected static freezePage() {
        LsModals.$html.addClass("lsc-modal-open");
    }

    protected static unfreezePage() {
        LsModals.$html.removeClass("lsc-modal-open");
    }

    protected static setCssVariables() {
        const height = LsModals.$window.height();
        LsModals.html.style.setProperty("--ls-window-height", `${height}px`);
        //const width = (LsModals.body.clientWidth < LsModals.window.innerWidth) ? LsModals.scrollbarWidth : 0;
        const width = LsModals.scrollbarWidth; // html has overflow-y: scroll
        LsModals.html.style.setProperty("--ls-scrollbar-width", width);
    }
}

export default LsModals;