import LsLogger from "~/Src/Components/Logging/Logger";

class LsSessionArray {
    // ReSharper disable InconsistentNaming
    protected static _sessionStorage: Storage;
    protected static get sessionStorage() {
        return LsSessionArray._sessionStorage || (LsSessionArray._sessionStorage = window.sessionStorage);
    }

    protected static _isSupported: boolean;
    protected static get isSupported() {
        if (LsSessionArray._isSupported === undefined) {
            LsSessionArray._isSupported = document.querySelector("html.sessionstorage") !== null;
            if (!LsSessionArray._isSupported) {
                LsLogger.trace("SessionStorage not supported.");
            }
        }
        return LsSessionArray._isSupported;
    }

    protected _values: Array<any>;
    protected get values() {
        if (this._values === undefined) {
            this._values = [];
            if (LsSessionArray.isSupported) {
                try {
                    const json = LsSessionArray.sessionStorage.getItem(this.key);
                    const values = JSON.parse(json);
                    if (Array.isArray(values)) {
                        this._values = values;
                    }
                } catch (ex) {
                    LsLogger.log(ex, "warn");
                    //LsLogger.trace(ex);
                }
            }
        }
        return this._values.slice();
    }
    protected set values(value: Array<any>) {
        this._values = value;
        if (LsSessionArray.isSupported) {
            try {
                LsSessionArray.sessionStorage.setItem(this.key, JSON.stringify(this._values));
            } catch (ex) {
                LsLogger.log(ex, "warn");
                //LsLogger.trace(ex);
            }
        }
    }

    public get length() {
        return this.values.length;
    }
    // ReSharper restore InconsistentNaming

    protected key: string;

    public constructor(key: string) {
        this.key = key;
    }

    public add = (...values: Array<any>) => {
        this.values = [...this.values, ...values];
    }

    public remove = (...values: Array<any>) => {
        this.values = this.values.filter(value => values.indexOf(value) === -1)
    }

    public removeAll = () => {
        const values = this.values.slice();
        this.values = [];
        return values;
    }

    public increment = () => {
        this.values = [...this.values, Date.now()];
    }

    public decrement = () => {
        this.values = this.values.slice(0, -1);
    }
}

export default LsSessionArray;