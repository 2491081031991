export type LsCallbackFunction = string | Function;
export type LsCallbackParameters = { [key: string]: any } | Array<any>;

export class LsCallback {
    // ReSharper disable once InconsistentNaming
    protected static _window: Window;
    protected static get window(): Window {
        return this._window || (LsCallback._window = window);
    }

    public static call(callbackFunction: LsCallbackFunction, callbackParameters: LsCallbackParameters = [], el: Element = null) {
        const parameters: Array<any> = [el];

        if (Array.isArray(callbackParameters)) {
            parameters.unshift(...callbackParameters);
        } else {
            parameters.unshift(callbackParameters);
        }

        if ((typeof callbackFunction === "string") && (callbackFunction in LsCallback.window)) {
            window[callbackFunction].apply(el, parameters);
        } else if (typeof callbackFunction === "function") {
            callbackFunction.apply(el, parameters);
        }
    }
}

export default LsCallback;