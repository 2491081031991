import $ = require("jquery");
import _debounce = require("lodash/debounce");

import LsSessionArray from "~/Src/Components/Utilities/SessionArray";

interface LsTraceLog {
    timestamp: string;
    message: string;
}

export type LsLoggerLevels = "error" | "fatal" | "info" | "warn" | "trace";

export class LsLogger {
    // ReSharper disable InconsistentNaming
    protected static _window: Window & typeof globalThis;
    protected static get window() {
        return LsLogger._window || (LsLogger._window = window);
    }

    protected static _$window: JQuery<Window>;
    protected static get $window() {
        return this._$window || (this._$window = $(LsLogger.window));
    }

    protected static _document: Document;
    protected static get document() {
        return LsLogger._document || (LsLogger._document = document);
    }

    protected _$document: JQuery<Document>;
    protected get $document() {
        return this._$document || (this._$document = $(LsLogger.document));
    }

    protected static _globalLoggerUrl: string;
    protected static get globalLoggerUrl() {
        if (LsLogger._globalLoggerUrl === undefined) {
            LsLogger._globalLoggerUrl = LsLogger.getLoggerUrl("lsGlobalLogger");
        }
        return LsLogger._globalLoggerUrl;
    }

    protected static _userActionLoggerUrl: string;
    protected static get userActionLoggerUrl() {
        if (LsLogger._userActionLoggerUrl === undefined) {
            LsLogger._userActionLoggerUrl = LsLogger.getLoggerUrl("lsUserActionLogger");
        }
        return LsLogger._userActionLoggerUrl;
    }

    protected static _exitLoggerUrl: string;
    protected static get exitLoggerUrl() {
        if (LsLogger._exitLoggerUrl === undefined) {
            LsLogger._exitLoggerUrl = LsLogger.getLoggerUrl("lsExitLogger");
        }
        return LsLogger._exitLoggerUrl;
    }

    protected static _isChromium: boolean;
    protected static get isChromium() {
        LsLogger._isChromium = LsLogger._isChromium || (LsLogger._isChromium = !!(window as any).chrome && (LsLogger.window.navigator.userAgent.indexOf("Edge") === -1));
        return LsLogger._isChromium;
    }

    protected static _unloadEvent: string;
    protected static get unloadEvent() {
        if (LsLogger._unloadEvent === undefined) {
            LsLogger._unloadEvent = (LsLogger.isChromium || (typeof PageTransitionEvent === "undefined")) ? "beforeunload" : "pagehide"; // must use typeof here for IE 9 & 10
        }
        return LsLogger._unloadEvent;
    }

    protected static _batchUserActions: { (): void; cancel(): void; }; // using ReturnType<typeof _debounce> breaks syntax highlighting in Chrome Developer Tools
    protected static get batchUserActions() {
        if (LsLogger._batchUserActions === undefined) {
            LsLogger._batchUserActions = _debounce(() => {
                if (LsLogger.traceLog.length >= 5) {
                    LsLogger.postUserActions();
                }
            }, 1000, { maxWait: 10 * 1000 });
        }
        return LsLogger._batchUserActions;
    }
    // ReSharper restore InconsistentNaming

    protected buttonSelectors = ['a[role="button"]:not([aria-disabled="true"])', "button", 'input[type="button"]', 'input[type="submit"]'];
    protected checkSelectors = ['input[type="checkbox"]', 'input[type="radio"]'];
    //protected textSelectors = ['input[type="date"]', 'input[type="datetime-local"]', 'input[type="email"]', 'input[type="password"]', 'input[type="tel"]', 'input[type="text"]', 'input[type="time"]', "textarea"];

    protected static initialized = false;
    protected static debug = true;
    protected static traceLog = new LsSessionArray("TraceLogs");

    public constructor() {
        if (!LsLogger.initialized) {
            this.$document.on("click", this.createSelectors(this.buttonSelectors), e => {
                const $el = $(e.currentTarget);
                const id = $el.data("logId");
                LsLogger.trace(`Clicked button [${id}]${this.logAttributes($el)}`);
            });

            this.$document.on("click", this.createSelectors(['a:not([role="button"]):not([aria-disabled="true"])']), e => {
                const $el = $(e.currentTarget);
                const id = $el.data("logId");
                LsLogger.trace(`Clicked link [${id}]${this.logAttributes($el)}`);
            });

            this.$document.on("change", this.createSelectors(this.checkSelectors), e => {
                const $el = $(e.currentTarget);
                const id = $el.data("logId");
                LsLogger.trace(`${(e.currentTarget as HTMLInputElement).checked ? "Checked" : "Unchecked"} ${(e.currentTarget as HTMLInputElement).type} [${id}]${this.logAttributes($el)}`);
            });

            this.$document.on("change", this.createSelectors(["select"]), e => {
                const $el = $(e.currentTarget);
                const id = $el.data("logId");
                const $selected = $el.find(":selected");
                const label = $selected.text();
                LsLogger.trace(`Selected [${label}] from [${id}]${this.logAttributes($el)}`);
            });

            this.$document.on("keydown", "form", e => {
                if ((e.which === 13) && ((e.target instanceof HTMLInputElement) || (e.target instanceof HTMLSelectElement))) {
                    const target = LsLogger.getIdentifier(e.target);
                    const currentTarget = LsLogger.getIdentifier(e.currentTarget);
                    LsLogger.trace(`Pressed enter key on [${target}] in [${currentTarget}]`);
                }
            });

            this.$document.on("mouseenter", this.createSelectors(['[data-toggle="hint"]']), e => {
                const $el = $(e.currentTarget);
                const id = $el.data("logId");
                LsLogger.trace(`Hovered hint [${id}]${this.logAttributes($el)}`);
            });

            LsLogger.initialized = true;
        }

        // none of the above currently differentiate between actual and triggered events
        // would like to log on input focus but determining actual or triggered focus is not possible -- https://github.com/jquery/jquery/issues/1741
    }

    public static log(message: string, level: LsLoggerLevels = "error") {
        if (level === "trace") {
            LsLogger.trace(message);
        } else if (LsLogger.globalLoggerUrl) {
            const data = {
                Message: message,
                Location: window.location.href,
                Level: level
            };

            if (LsLogger.debug && ("console" in LsLogger.window)) {
                LsLogger.window.console.log(data);
            }

            const json = JSON.stringify(data);
            $.ajax({
                url: LsLogger.globalLoggerUrl,
                type: "POST",
                global: false,
                contentType: "application/json",
                data: json
            });
        }
    }

    public static trace(...messages: Array<string>) {
        if (LsLogger.debug && ("console" in LsLogger.window)) {
            for (const message of messages) {
                LsLogger.window.console.log(message);
            }
        }

        if (LsLogger.traceLog.length === 0) {
            LsLogger.$window.on(LsLogger.unloadEvent, LsLogger.postExit);
        }

        const timestamp = (new Date()).toISOString();
        const logs: Array<LsTraceLog> = messages.map(message => ({ timestamp, message }));

        LsLogger.traceLog.add(...logs);

        LsLogger.batchUserActions();
    }

    public static getTraceData() {
        let data = {};
        if (LsLogger.traceLog.length > 0) {
            const logs: Array<LsTraceLog> = LsLogger.traceLog.removeAll();

            LsLogger.$window.off(LsLogger.unloadEvent, LsLogger.postExit);
            LsLogger.batchUserActions.cancel();

            const entries = logs.map(log => `[${log.timestamp}] ${log.message}`);
            data = { traceLogs: entries.join("\n \t") }; // newline to separate log entries, space for empty caller, tab to separate caller from message
        }
        return data;
    }

    public static excerpt(content: string, length: number, stripHtml = true, escapeHtml = false) {
        if (content) {
            if (stripHtml) {
                content = $(`<div>${content}</div>`).text();
            }
            if (escapeHtml) {
                content = escape(content);
            }
            const long = content.length > length;
            return long ? `${content.substr(0, length)}...` : content;
        }
        return "";
    }

    public static isGlobalLoggerUrl(url: string) {
        if (url) {
            let loggerUrl = LsLogger.globalLoggerUrl;
            if (loggerUrl) {
                const a = LsLogger.document.createElement("a");

                a.href = url;
                url = a.href;

                a.href = loggerUrl;
                loggerUrl = a.href;

                return url === loggerUrl;
            }
        }
        return false;
    }

    protected static postUserActions() {
        LsLogger.postTrace(LsLogger.userActionLoggerUrl);
    }

    protected static postExit() {
        LsLogger.postTrace(LsLogger.exitLoggerUrl);
    }

    protected static postTrace(loggerUrl: string) {
        if (loggerUrl && LsLogger.traceLog.length > 0) {
            const data = LsLogger.getTraceData();
            const $token = $("#LayoutAntiForgeryToken input");
            if ($token.length > 0) {
                data[$token.attr("name")] = $token.val();
            }
            $.ajax({
                url: loggerUrl,
                method: "POST",
                global: false,
                async: LsLogger.isChromium,
                data: data
            });
        }
    }

    protected createSelectors = (elements: Array<string>) => {
        const selectors = elements.map(element => `${element}[data-log-id]`);
        return selectors.join(", ");
    }

    protected logAttributes = ($el: JQuery) => {
        let attributes: string | Array<string> = $el.data("logAttributes");

        if ((typeof attributes === "string") && (attributes.length > 0)) {
            attributes = [attributes];
        }

        if (Array.isArray(attributes) && (attributes.length > 0)) {
            const data: { [key: string]: any } = {};

            for (let attribute of attributes) {
                data[attribute] = $el.attr(attribute);
                if (attribute.substr(0, 5) === "data-") {
                    const k = attribute.substr(5).toLowerCase().replace(/^-+|-+(\w)|-+$/g, (m, p1) => (p1 || "").toUpperCase());
                    const v = $el.data(k);
                    if (v !== data[attribute]) {
                        data[`data:${k}`] = v;
                    }
                }
            }

            const pairs = Object.keys(data).map(k => {
                return ` [${k}=${data[k]}]`;
            });
            return pairs.join("");
        }

        return "";
    }

    public static getIdentifier = (el: Element) => {
        return el.getAttribute("data-log-id") || el.id || (el as HTMLInputElement).name || `unknown ${el.tagName.toLowerCase()}`;
    }

    protected static getLoggerUrl(id: string) {
        const logger = LsLogger.window.document.getElementById(id);
        if (logger) {
            const url = logger.getAttribute("data-url");
            if (url) {
                return url;
            }
        }
        return undefined;
    }
}

export default LsLogger;