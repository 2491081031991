//added for code review
export var getCookie = (name: string) => {
    try {
        const re = new RegExp(name + "=([^;]+)");
        const value = re.exec(document.cookie);
        return (value !== null) ? unescape(value[1]) : null;
    } catch (e) {
        return null;
    }
};

export var setCookie = (cname: string, cvalue: string, exdays: number) => {
    let expires;
    if (exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires = `expires=${d.toUTCString()}`;
    }

    document.cookie = `${cname}=${cvalue}; ${expires}; path=/`;
};

export var uniqueId = (el: Element, prefix: string = "uniqueId") => {
    const d = document;
    let id = el.id;
    if (!id) {
        do {
            id = `${prefix}-${Math.random().toString(16).slice(2)}`;
        } while (d.getElementById(id));
        el.id = id;
    }
    return id;
}

export var forceRedraw = (el: Element) => {
    const __ = (el as HTMLElement).offsetHeight;
}

export var measureScrollbar = () => {
    const d = document;
    let div = d.createElement("div");

    div.style.height = "99px";
    div.style.width = "99px";
    div.style.overflow = "scroll";
    div.style.position = "absolute";
    div.style.top = "-999px";

    d.body.appendChild(div);

    const width = div.offsetWidth - div.clientWidth;

    d.body.removeChild(div);
    div = null;

    return `${width}px`;
}

export var generateUUID = () => {
    const w = window;
    let d = (new Date()).getTime();
    if (("performance" in w) && (typeof w.performance.now === "function")) {
        d += performance.now(); //use high-precision timer if available
    }
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (d + (Math.random() * 16)) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

export var debounce = (callback: () => void) => {
    const w = window;
    let frameId: ReturnType<typeof window.requestAnimationFrame>;
    return () => {
        w.cancelAnimationFrame(frameId);
        frameId = w.requestAnimationFrame(callback);
    };
}

export function includes(container: Array<any>, value: any) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
        returnValue = true;
    }
    return returnValue;
}