import $ = require("jquery");

import { LsBreakpoints, LsBreakpoint } from "~/Src/Components/Breakpoints/Breakpoints";
import LsModals from "~/Src/Components/Modal/Modal";
import LsTransition from "~/Src/Components/Transition/Transition";
import { forceRedraw } from "~/Src/Components/Utilities/Utilities";

import "./Hint.scss";

class ElementPositioningSystem {
    protected $el: JQuery<Element>;

    // ReSharper disable InconsistentNaming
    protected _left: number;
    protected _top: number;
    protected _width: number;
    protected _height: number;

    protected _xMin: number;
    protected _xMid: number;
    protected _xMax: number;
    protected _yMin: number;
    protected _yMid: number;
    protected _yMax: number;
    // ReSharper restore InconsistentNaming

    public constructor(el: Element) {
        this.$el = $(el);
    }

    protected get left(): number {
        if (this._left === undefined) {
            const { left, top } = this.$el.offset();
            this._left = left;
            this._top = top;
        }
        return this._left;
    }

    protected get top(): number {
        if (this._top === undefined) {
            const { left, top } = this.$el.offset();
            this._left = left;
            this._top = top;
        }
        return this._top;
    }

    protected get width(): number {
        if (this._width === undefined) {
            this._width = this.$el.outerWidth();
        }
        return this._width;
    }

    protected get height(): number {
        if (this._height === undefined) {
            this._height = this.$el.outerHeight();
        }
        return this._height;
    }

    public get xMin(): number {
        if (this._xMin === undefined) {
            this._xMin = this.left;
        }
        return this._xMin;
    }

    public get xMid(): number {
        if (this._xMid === undefined) {
            this._xMid = this.left + (this.width / 2);
        }
        return this._xMid;
    }

    public get xMax(): number {
        if (this._xMax === undefined) {
            this._xMax = this.left + this.width;
        }
        return this._xMax;
    }

    public get yMin(): number {
        if (this._yMin === undefined) {
            this._yMin = this.top;
        }
        return this._yMin;
    }

    public get yMid(): number {
        if (this._yMid === undefined) {
            this._yMid = this.top + (this.height / 2);
        }
        return this._yMid;
    }

    public get yMax(): number {
        if (this._yMax === undefined) {
            this._yMax = this.top + this.height;
        }
        return this._yMax;
    }
}

export interface ILsHintOptions {
    readonly target?: string;
    readonly title?: string;
    readonly adjacent?: LsJQuerySelector;
    readonly position?: "top" | "left" | "right" | "bottom";
    readonly width?: string;
    readonly preventDefault?: boolean;
    readonly breakpoints?: {
        [bp: number]: string;
    };
}

export class LsHintOptions implements ILsHintOptions {
    public readonly width: string = "";
    public readonly preventDefault: boolean = true;
    public readonly breakpoints = { 0: "modal", [LsBreakpoint.spf48]: "tooltip" };
}

export class LsHint {
    // ReSharper disable InconsistentNaming
    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsHint._$document || (LsHint._$document = $(document));
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected static gap = 10;

    public constructor() {
        if (!LsHint.initialized) {
            LsHint.$document.on("mouseenter", '[data-toggle="hint"]:not(input):not(textarea)', LsHint.show);
            LsHint.$document.on("mouseleave", '[data-toggle="hint"]:not(input):not(textarea)', LsHint.hide);
            LsHint.$document.on("click", '[data-toggle="hint"]:not(input):not(textarea)', this.onClick);
            LsHint.$document.on("touchstart", '[data-toggle="hint"]:not(input):not(textarea)', this.onTouch);
            LsHint.$document.on("mouseenter", 'input[data-toggle="hint"], textarea[data-toggle="hint"]', e => LsHint.clearTitle(e.currentTarget));
            LsHint.$document.on("focusin", 'input[data-toggle="hint"], textarea[data-toggle="hint"]', LsHint.show);
            LsHint.$document.on("focusout", 'input[data-toggle="hint"], textarea[data-toggle="hint"]', LsHint.hide);

            LsHint.initialized = true;
        }
    }

    public static show(e: LsJQueryEvent, options?: ILsHintOptions);
    public static show(el: LsJQuerySelector, options?: ILsHintOptions);
    public static show(arg: LsJQueryEvent | LsJQuerySelector, options?: ILsHintOptions) {
        if (LsHint.isEvent(arg)) {
            arg.preventDefault();
        }

        const $trigger = $(LsHint.isEvent(arg) ? arg.currentTarget : arg);
        LsHint.clearTitle($trigger);

        const defaults = $.extend(
            {
                adjacent: $trigger,
                position: ($trigger.is("input, textarea") ? "right" : "top") as "right" | "top",
            },
            new LsHintOptions()
        );
        if (options !== undefined) {
            options = $.extend({}, defaults, options);
        } else {
            options = $.extend({}, defaults, $trigger.data());
        }

        LsHint.hide();

        const $template = $("#hint-popup-template");
        if ($template.length > 0) {
            const popup = $template.html();
            const $popup = $(popup).appendTo("body");

            const $notch = $popup.find(".lsc-hint-notch");

            if (options.target !== undefined) {
                const k = LsBreakpoints.maxMatch(Object.keys(options.breakpoints).map(s => parseInt(s)));
                switch (options.breakpoints[k]) {
                    case "none":
                    default:
                        return;
                    case "modal":
                        LsModals.openModalByTarget(options.target);
                        return;
                    case "tooltip":
                        const $target = $(options.target);
                        if ($target.length === 0) {
                            return;
                        }
                        const html = $target.html();
                        $popup.append(html);
                        break;
                }
            } else if (options.title !== undefined) {
                const k = LsBreakpoints.maxMatch(Object.keys(options.breakpoints).map(s => parseInt(s)));
                switch (options.breakpoints[k]) {
                    case "none":
                    default:
                        return;
                    case "modal":
                        LsModals.openModalByContent(options.title);
                        return;
                    case "tooltip":
                        $popup.append(options.title);
                        break;
                }
            }

            let $adjacent: JQuery = $(options.adjacent);
            if ($adjacent.length === 0) {
                $adjacent = $trigger;
            }

            $trigger.data(options);

            $popup.attr("data-position", options.position);
            $popup.css("width", options.width);
            $popup.prop("hidden", false);
            forceRedraw($popup.get(0));
            const popupHeight = $popup.outerHeight();
            const popupWidth = $popup.outerWidth();
            $popup.prop("hidden", true);
            const adjacentPos = new ElementPositioningSystem($adjacent.get(0));
            const windowWidth = $(window).width();
            const documentHeight = LsHint.$document.height();
            let popupTop, popupLeft, notchTop, notchLeft;
            switch (options.position) {
                case "top":
                    popupTop = (adjacentPos.yMin - popupHeight) - LsHint.gap;
                    break;
                case "left":
                    popupLeft = (adjacentPos.xMin - popupWidth) - LsHint.gap;
                    break;
                case "right":
                    popupLeft = adjacentPos.xMax + LsHint.gap;
                    break;
                case "bottom":
                    popupTop = adjacentPos.yMax + LsHint.gap;
                    break;
            }
            switch (options.position) {
                case "top":
                case "bottom":
                    popupLeft = adjacentPos.xMid - (popupWidth / 2);
                    if (popupLeft < 0) {
                        popupLeft = 0;
                        notchLeft = adjacentPos.xMid;
                    } else if ((popupLeft + popupWidth) > windowWidth) {
                        popupLeft = windowWidth - popupWidth;
                        notchLeft = adjacentPos.xMid - popupLeft;
                    }
                    break;
                case "left":
                case "right":
                    popupTop = adjacentPos.yMid - (popupHeight / 2);
                    if (popupTop < 0) {
                        popupTop = 0;
                        notchTop = adjacentPos.yMid;
                    } else if ((popupTop + popupHeight) > documentHeight) {
                        popupTop = documentHeight - popupHeight;
                        notchTop = adjacentPos.yMid - popupTop;
                    }
                    break;
            }
            $popup.offset({
                top: popupTop,
                left: popupLeft
            });
            if (notchTop !== undefined) {
                $notch.css("top", notchTop);
            }
            if (notchLeft !== undefined) {
                $notch.css("left", notchLeft);
            }
            LsTransition.in($popup);

            // enable touch anywhere to close
            LsHint.$document.on("touchstart", LsHint.hide);
        }
    }

    public static hide = (e?: JQuery.TriggeredEvent) => {
        if (e !== undefined) {
            e.preventDefault();
        }

        const $popup = $(".lsc-hint-popup");
        $popup.each((i, el: Element) => {
            LsTransition.out(el);
        });

        // clean up in case transition never occurs
        window.setTimeout(() => {
            $popup.trigger("transitionend");
        }, 1000);

        // disable touch anywhere to close
        LsHint.$document.off("touchstart", LsHint.hide);
    }

    protected onClick = (e: LsJQueryEvent) => {
        const $trigger = $(e.currentTarget);
        const preventDefault = !($trigger.data("preventDefault") === false);

        if (preventDefault) {
            e.preventDefault();
        }
    }

    protected onTouch = (e: LsJQueryEvent) => {
        const $trigger = $(e.currentTarget);
        const preventDefault = !($trigger.data("preventDefault") === false);

        e.stopPropagation();
        if (preventDefault) {
            e.preventDefault();
        }

        const $popup = $(".lsc-hint-popup");
        if ($popup.is(":visible")) {
            LsHint.hide(preventDefault ? e : undefined);
        } else {
            LsHint.show(e);
        }
    }

    protected static clearTitle(el: LsJQuerySelector) {
        const $trigger = $(el);
        const title = $trigger.attr("title");
        if (title) {
            $trigger.data("title", $trigger.attr("title"));
            $trigger.removeAttr("title");
        }
    }

    protected static isEvent(e: any): e is LsJQueryEvent {
        return e instanceof $.Event;
    }
}

export default LsHint;