import LsLogger from "~/Src/Components/Logging/Logger";
import LsTransition from "~/Src/Components/Transition/Transition";

import "./Loading.scss";

class LsLoadingOptions {
    public static overlayId = "lsLoadingOverlay";
    public static delay = 100;
}

export class LsLoading {
    protected static _window: Window;
    protected static get window() {
        return LsLoading._window || (LsLoading._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsLoading._document || (LsLoading._document = document);
    }

    protected static timer: number;
    protected static overlayDisabled = false;

    public static showOverlay() {
        if (!LsLoading.overlayDisabled) {
            const loading = LsLoading.document.getElementById(LsLoadingOptions.overlayId);
            if (loading) {
                LsLogger.trace("Showing loading overlay.");
                const delay = parseInt(loading.dataset.delay);
                LsLoading.window.clearTimeout(LsLoading.timer);
                LsLoading.timer = LsLoading.window.setTimeout(() => {
                    if (!LsLoading.overlayDisabled) {
                        LsTransition.in(loading);
                    }
                }, isNaN(delay) ? LsLoadingOptions.delay : delay);
            } else {
                LsLogger.trace("Loading overlay not found. Not showing loading overlay.");
                LsLogger.log(`Loading.showOverlay - element [#${LsLoadingOptions.overlayId}] not found`, "fatal");
            }
        } else {
            LsLogger.trace("Loading overlay disabled. Not showing loading overlay.");
        }
    }

    public static hideOverlay() {
        LsLoading.window.clearTimeout(LsLoading.timer);
        const loading = LsLoading.document.getElementById(LsLoadingOptions.overlayId);
        if (loading) {
            LsLogger.trace("Hiding loading overlay.");
            LsTransition.out(loading);
        } else {
            LsLogger.trace("Loading overlay not found. Not hiding loading overlay.");
            LsLogger.log(`Loading.hideOverlay - element [#${LsLoadingOptions.overlayId}] not found`, "fatal");
        }
    }

    public static enableOverlay() {
        LsLogger.trace("Enabling loading overlay.");
        LsLoading.overlayDisabled = false;
    }

    public static disableOverlay() {
        LsLogger.trace("Disabling loading overlay.");
        LsLoading.overlayDisabled = true;
    }
}

export default LsLoading;